import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import abductionIllustration from "../images/abduction-illustration.svg";

function NotFoundPage() {
  return (
    <div>
      <SEO title="404: Not found" />
      <div className="bg-gradient-primary min-h-screen px-6 text-center">
        <div className="max-w-6xl mx-auto">
          <img
            alt="Ghost getting abducted by aliens"
            className="block mx-auto w-1/2"
            src={abductionIllustration}
          />
          <div className="bg-white border border-pink-50 max-w-2xl mx-auto my-20 rounded-lg shadow-md">
            <h2 className="bg-yellow-400 text-xl font-bold inline-block my-8 p-3 uppercase">
              <a href="/" className="text-pink-50">
                ⤎ Back to home
              </a>{" "}
              | 404: Page not found
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFoundPage;
